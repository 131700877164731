export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [6,[2],[3]],
		"/(app)/action": [7],
		"/(app)/ai-search": [9,[2],[3]],
		"/(app)/ai": [8],
		"/(app)/call": [10,[2],[3]],
		"/(app)/compose": [11],
		"/(app)/connected": [13,[2],[3]],
		"/(app)/connect": [12,[2],[3]],
		"/(app)/data": [14,[2],[3]],
		"/(app)/demo": [15],
		"/(app)/done": [16],
		"/(app)/embeddingTest": [17],
		"/(app)/experiment": [18],
		"/(app)/insights": [19,[2],[3]],
		"/(app)/life": [20,[2],[3]],
		"/(app)/login": [21],
		"/(app)/logout": [22],
		"/(app)/onboard": [23],
		"/(app)/onboard/apple": [24,[2,4],[3]],
		"/(app)/onboard/call": [25,[2,4],[3]],
		"/(app)/onboard/companies": [26,[2,4],[3]],
		"/(app)/onboard/complete": [27,[2,4],[3]],
		"/(app)/onboard/connect": [28,[2,4],[3]],
		"/(app)/onboard/demo": [29],
		"/(app)/onboard/download": [30,[2,4],[3]],
		"/(app)/onboard/insights": [31,[2,4],[3]],
		"/(app)/onboard/intro": [32,[2,4],[3]],
		"/(app)/onboard/intro/control": [33,[2,4],[3]],
		"/(app)/onboard/intro/understand": [34,[2,4],[3]],
		"/(app)/onboard/intro/use": [35,[2,4],[3]],
		"/(app)/onboard/privacy": [36,[2,4],[3]],
		"/(app)/onboard/recommendation": [37,[2,4],[3]],
		"/(app)/onboard/screentime": [38,[2,4],[3]],
		"/(app)/onboard/spotify": [39,[2,4],[3]],
		"/(app)/onboard/subscribe": [40,[2,4],[3]],
		"/(app)/onboard/sync": [41,[2,4],[3]],
		"/(app)/onboard/track": [42,[2,4],[3]],
		"/(app)/onboard/twitter": [43,[2,4],[3]],
		"/(app)/onboard/value": [44,[2,4],[3]],
		"/(app)/profile": [45,[2,5],[3]],
		"/(app)/profile/account": [46,[2,5],[3]],
		"/(app)/profile/data-choices": [47,[2,5],[3]],
		"/(app)/profile/plans": [48,[2,5],[3]],
		"/(app)/profile/referrals": [49,[2,5],[3]],
		"/(app)/register": [50],
		"/(app)/session": [51],
		"/(app)/social": [52,[2],[3]],
		"/(app)/steering": [53,[2],[3]],
		"/(app)/subscribed": [55,[2],[3]],
		"/(app)/sub": [54],
		"/(app)/suggest": [56],
		"/(app)/time": [57,[2],[3]],
		"/(app)/upcoming-features": [58,[2],[3]],
		"/(app)/upload": [59,[2],[3]],
		"/(app)/whoop": [60,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';